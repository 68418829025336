const SmileIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.99 2.95508C6.47 2.95508 2 7.43508 2 12.9551C2 18.4751 6.47 22.9551 11.99 22.9551C17.52 22.9551 22 18.4751 22 12.9551C22 7.43508 17.52 2.95508 11.99 2.95508ZM12 20.9551C7.58 20.9551 4 17.3751 4 12.9551C4 8.53508 7.58 4.95508 12 4.95508C16.42 4.95508 20 8.53508 20 12.9551C20 17.3751 16.42 20.9551 12 20.9551ZM17 10.4551C17 11.2851 16.33 11.9551 15.5 11.9551C14.67 11.9551 14 11.2851 14 10.4551C14 9.62508 14.67 8.95508 15.5 8.95508C16.33 8.95508 17 9.62508 17 10.4551ZM8.5 11.9551C9.33 11.9551 10 11.2851 10 10.4551C10 9.62508 9.33 8.95508 8.5 8.95508C7.67 8.95508 7 9.62508 7 10.4551C7 11.2851 7.67 11.9551 8.5 11.9551ZM16.75 15.7051C15.8 17.3451 14.03 18.4551 12 18.4551C9.97 18.4551 8.2 17.3451 7.25 15.7051C7.06 15.3751 7.31 14.9551 7.69 14.9551H16.31C16.7 14.9551 16.94 15.3751 16.75 15.7051Z'
      />
    </svg>
  );
};

export { SmileIcon };
