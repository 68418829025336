import pluralize from 'pluralize';
import { useState } from 'react';

import { type DtoInquiry } from '@lp-lib/api-service-client/public';

import { EditIcon } from '../icons/EditIcon';
import { LockIcon } from '../icons/LockIcon';
import { LPLogo } from '../icons/LPLogo';
import { SmileIcon } from '../icons/SmileIcon';
import { UserJoyCapture } from '../JoyCapture';
import { Loading } from '../Loading';

export function Inquirer(props: { uid: string; name: string }): JSX.Element {
  const { uid, name } = props;
  return (
    <div className='flex items-center gap-2.5 text-sms'>
      <span className='text-icon-gray'>Request from:</span>
      <div className='flex items-center gap-1.5'>
        <UserJoyCapture
          uid={uid}
          styles={{ size: 'w-6 h-6', text: 'text-2xs' }}
          noAnimate
        />
        <span>{name}</span>
      </div>
    </div>
  );
}

export function WelcomePage(props: {
  inquiry: DtoInquiry;
  onStart?: () => Promise<void>;
}): JSX.Element {
  const { inquiry } = props;
  const [isStarting, setIsStarting] = useState(false);

  const handleStart = async () => {
    setIsStarting(true);
    try {
      await props.onStart?.();
    } finally {
      setIsStarting(false);
    }
  };

  return (
    <div className='w-full h-full text-white flex flex-col justify-between bg-layer-001'>
      <header className='hidden w-full md:flex items-center pl-2.5 pt-2.5 pr-5 justify-between'>
        <LPLogo />
        <Inquirer uid={inquiry.uid} name={inquiry.inquirerName} />
      </header>

      <div className='pt-12 flex flex-col items-center justify-center gap-6'>
        <div className='md:hidden'>
          <Inquirer uid={inquiry.uid} name={inquiry.inquirerName} />
        </div>
        <div className='text-xl md:text-3.5xl font-bold text-center'>
          Start your AI Interview
        </div>
      </div>

      <main className='flex justify-center'>
        <div className='flex-1 w-full lg:max-w-200 px-5 flex flex-col items-center justify-center gap-7.5'>
          <div className='mt-2 text-sms font-bold text-center px-5 line-clamp-3 md:line-clamp-6'>
            {inquiry.query}
          </div>
          <div className='font-bold'>Here’s what to expect</div>
          <div className='space-y-4'>
            <div className='bg-main-layer rounded-md flex items-center p-4 gap-4'>
              <SmileIcon className='w-6 h-6 fill-current flex-none' />
              <div className='leading-tight'>
                Just chat with us like you're talking to a friend - the more you
                share, the better!
              </div>
            </div>
            <div className='bg-main-layer rounded-md flex items-center p-4 gap-4'>
              <LockIcon className='w-6 h-6 fill-current flex-none' />
              <div className='leading-tight'>
                No stress, we won't share any raw recordings or transcripts, but
                we'll sum up the convo for you.
              </div>
            </div>
            <div className='bg-main-layer rounded-md flex items-center p-4 gap-4'>
              <EditIcon className='w-6 h-6 fill-current flex-none' />
              <div className='leading-tight'>
                You get the final say - review and okay everything before it
                goes out.
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className='px-9.5 pb-12 md:pb-18 flex flex-col items-center gap-5'>
        <button
          type='button'
          className={`
            btn w-full h-16 md:max-w-75
            flex items-center justify-center
            bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
            font-bold text-center tracking-wide
          `}
          onClick={handleStart}
          disabled={isStarting}
        >
          {isStarting ? <Loading text='' /> : 'Start Interview'}
        </button>
        <div className='text-center text-icon-gray font-bold'>
          {`${inquiry.questionIds.length} ${pluralize(
            'question',
            inquiry.questionIds.length
          )} total`}
        </div>
      </footer>
    </div>
  );
}
